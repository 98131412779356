<template>
  <v-card>
    <v-card-title>{{ `${$t('menu.configuration')} /  ${$t('landing.evento')} ${evento.id ? ` / ${evento.title}` : ''}` }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="error"
            class="mb-4 me-3"
            @click="$router.push({ name: 'events-list' })"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>{{ $t('btn.back') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            v-if="!$store.state.app.onlyShow"
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="save()"
          >
            <span>{{ $t('btn.send') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-form
        class="multi-col-validation"
      >
        <v-row
          class="match-height"
        >
          <v-col cols="4">
            <v-text-field
              v-model="evento.title"
              :label="`${$t('lbl.title')}`"
              outlined
              dense
              hide-details="auto"
              :rules="textRequiered"
              :disabled="evento.vencido"
              @input="generateSlug"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="evento.slug"
              label="Slug/URL"
              outlined
              dense
              hide-details="auto"
              :rules="textRequiered"
              :disabled="evento.id ? true : false"
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
          >
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedFromDateFormatted"
                  :label="$t('landing.eventoFrom')"
                  persistent-hint
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  :disabled="evento.vencido"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="evento.dateFrom"
                no-title
                :locale="$i18n.locale"
                :show-current="false"
                :min="minDate ? minDate.toISOString().substr(0, 10) : ''"
                @input="
                  menuFrom = false
                  activeToDate()
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="3"
          >
            <v-menu
              v-model="menuTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateToFormatted"
                  :disabled="!evento.dateFrom || evento.vencido"
                  :label="$t('landing.eventoTo')"
                  persistent-hint
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="evento.dateTo"
                no-title
                :locale="$i18n.locale"
                :show-current="false"
                :min="
                  evento.dateFrom
                    ? $moment(evento.dateFrom)
                      .add(0, 'day')
                      .toDate()
                      .toISOString()
                      .substr(0, 10)
                    : ''
                "
                @input="menuTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="evento.description"
              :label="`${$t('lbl.description')}`"
              outlined
              dense
              rows="2"
              hide-details="auto"
              :rules="textRequiered"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="evento.pabellon"
              :label="`${$t('landing.eventoPabellon')}`"
              outlined
              dense
              hide-details="auto"
              :disabled="evento.vencido"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="evento.stand"
              :label="`${$t('landing.eventoStand')}`"
              outlined
              dense
              hide-details="auto"
              :disabled="evento.vencido"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="evento.country"
              :items="countries"
              :label="$t('register.country')"
              item-text="name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
              :disabled="evento.vencido"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="evento.city"
              :label="`${$t('register.locality')}`"
              outlined
              dense
              hide-details="auto"
              :disabled="evento.vencido"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-file-input
              v-if="!pdf_catalog"
              v-model="pdf"
              accept=".pdf"
              show-size
              outlined
              dense
              :label="$t('landing.catalog')"
              hide-details
              :disabled="evento.vencido"
              @change="setCatalog"
            ></v-file-input>
            <v-select
              v-else
              v-model="evento.catalog_id"
              :items="catalogs"
              :label="$t('landing.catalog')"
              outlined
              dense
              item-text="title"
              item-value="id"
              hide-details="auto"
              :disabled="evento.vencido"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="pdf_catalog"
              hide-details
              class="mt-1"
              :label="$t('landing.eventoPdfExtern')"
              :disabled="evento.vencido"
              @click:event="setChangePdf"
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-file-input
              v-model="logo"
              accept="image/*"
              show-size
              outlined
              dense
              label="Logo"
              hide-details
              :disabled="evento.vencido"
              @change="setLogo"
            ></v-file-input>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <template v-if="evento.id">
              <v-file-input
                v-model="imgs"
                accept=".jpg, .png, .webp"
                show-size
                outlined
                dense
                :label="$t('landing.eventoFotos')"
                hide-details
                multiple
                @change="addImgs"
              ></v-file-input>
            </template>
            <template v-else>
              <v-file-input
                v-model="imgs"
                accept=".jpg, .png, .webp"
                show-size
                outlined
                dense
                :label="$t('landing.eventoFotos')"
                hide-details
                multiple
                :disabled="evento.vencido"
                @change="setImgs"
              ></v-file-input>
            </template>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-text>
      <v-row v-if="evento.logo">
        <v-col
          cols="12"
          md="3"
        >
          <v-img
            height="100px"
            contain
            :src="urlS3 + evento.logo"
          ></v-img>
        </v-col>

        <v-col cols="12">
          <v-divider class="mb-2 mt-2"></v-divider>
        </v-col>

        <template v-if="evento.imgs.length > 0">
          <v-col cols="12">
            <v-row>
              <v-col
                v-for="(imag, indIm) in evento.imgs"
                :key="indIm"
                cols="4"
              >
                <v-img
                  class="cursor-pointer"
                  height="250px"
                  :src="urlS3 + imag"
                  @click="editImg(imag)"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider class="mb-2 mt-2"></v-divider>
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <b>{{ $t('landing.event.socios') }}</b>
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <v-tooltip
                v-if="showRowSocios && !$store.state.app.onlyShow"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-1 mx-2"
                    fab
                    dark
                    x-small
                    color="primary"
                    v-bind="attrs"
                    @click="newSocio"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <v-btn
                icon
                @click="showRowSocios = !showRowSocios"
              >
                <v-icon>
                  {{ showRowSocios ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="showRowSocios">
            <v-col
              v-if="evento.socios.length > 0"
              cols="12"
            >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        class="text-uppercase"
                        width="30%"
                      >
                        {{ $t('lbl.title') }}
                      </th>
                      <th
                        class="text-uppercase"
                      >
                        {{ $t('lbl.description') }}
                      </th>
                      <th
                        width="150px"
                        class="text-uppercase"
                        style="text-align: center;"
                      >
                        {{ $t('lbl.actions') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(soc, index) in evento.socios">
                      <tr
                        v-if="socios.filter(e => e.id === soc).length > 0"
                        :key="index"
                      >
                        <td>
                          <v-avatar
                            class="profile"
                            size="60"
                            tile
                          >
                            <v-img
                              :src="urlS3 + socios.filter(e => e.id === soc)[0].logo"
                              :alt="socios.filter(e => e.id === soc)[0].title"
                              contain
                            ></v-img>
                          </v-avatar>
                        </td>
                        <td>
                          {{ socios.filter(e => e.id === soc)[0].title }}
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >{{ socios.filter(e => e.id === soc)[0].description | truncate(30) }}</span>
                            </template>
                            <span>{{ socios.filter(e => e.id === soc)[0].description }}</span>
                          </v-tooltip>
                        </td>
                        <td
                          style="text-align: center;"
                        >
                          <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item
                                link
                                @click="deleteSocio(index)"
                              >
                                <v-list-item-title>
                                  <v-btn icon>
                                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                                  </v-btn>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <!--<SidebarDataSocio
      v-if="isSidebarDataSocioActive"
      v-model="isSidebarDataSocioActive"
      :socio="socio"
      @updateSocios="updateSocios"
    />-->
    <!-- AGREGAR SOCIO -->
    <v-dialog
      v-model="isDialogSocio"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('landing.event.socios') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="socioId"
                :items="sociosList"
                :label="$t('lbl.name')"
                outlined
                dense
                item-text="title"
                item-value="id"
                hide-details="auto"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogSocio = !isDialogSocio"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="updateSocios"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showImg"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="showImg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-btn
              dark
              text
              @click="confirmDeleteImgEvent"
            >
              {{ $t('btn.delete') }}
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-img
            max-height="640px"
            :src="urlS3 + imgEdit"
            contain
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

// import { v4 as uuidv4 } from 'uuid'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
  mdiMenuDown,
  mdiMenuRight,
  mdiDotsVertical,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

// import SidebarDataSocio from '../utils/SidebarDataSocio.vue'

export default {
  components: {
    Loading,

    // SidebarDataSocio,
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      showRowSocios: false,
      isSidebarDataSocioActive: false,
      isLoading: true,
      isDialogBloqueo: false,
      loading: false,
      socioId: null,
      evento: {
        id: null,
        dateFrom: null,
        dateTo: null,
        socios: [],
      },
      socio: {},
      agendarOps: [],
      socios: [],
      qr: null,
      img: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
        mdiMenuDown,
        mdiMenuRight,
        mdiDotsVertical,
      },
      model: {},
      id_model: -1,
      permisos: localStorage.getItem('permisos_auth'),
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      catalogs: [],
      countries: [],
      paisUser: {},
      pos: 0,
      payMents: [],
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      imgs: [],
      logo: null,
      pdf: null,
      menuFrom: false,
      menuTo: false,
      pdf_catalog: false,
      isDialogSocio: false,
      imgEdit: {},
      showImg: false,
      minDate: null,
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
    computedFromDateFormatted() {
      return this.evento.dateFrom ? this.$moment(this.evento.dateFrom).locale(this.$i18n.locale).format('D-MMM-YY') : ''
    },
    computedDateToFormatted() {
      return this.evento.dateTo ? this.$moment(this.evento.dateTo).locale(this.$i18n.locale).format('D-MMM-YY') : ''
    },
    sociosList() {
      const arr = []

      this.socios.forEach(element => {
        if (!this.evento.socios.includes(element.id)) {
          arr.push(element)
        }
      })

      return arr
    },
  },
  created() {
    this.fetchDataFromEndpoints()
    if (localStorage.getItem('event-id')) {
      this.getEvent()
      this.getQR()
    } else {
      this.minDate = new Date()
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    async fetchDataFromEndpoints() {
      try {
        const [
          resCountries,
          resCatalogs,
          resSocios,
        ] = await Promise.all([
          this.axios
            .get('country_list?per_page=1000'),
          this.axios
            .post('catalogs/list-catalogos'),
          this.axios
            .post('socios/list', { per_page: 1000000 }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        this.countries = resCountries.data.data
        this.catalogs = resCatalogs.data.data

        // eslint-disable-next-line no-empty
        if (resSocios.data.success === false) { } else {
          this.socios = resSocios.data.data
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async getEvent() {
      await this.axios
        .post('events/show-event', { id: localStorage.getItem('event-id') }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {} else {
            this.evento = res.data.data.item
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getQR() {
      this.axios
        .post('catalogs/qr-event', { slug: this.$route.params.slug })
        .then(res => {
          this.qr = res.data
        })
    },

    setChangePdf() {
      this.pdf_catalog = !this.pdf_catalog
      this.evento.pdf_catalog = this.pdf_catalog
    },
    generateSlug() {
      // Convertir a minúsculas
      let text = this.evento.title.toLowerCase()

      // Reemplazar espacios y caracteres especiales
      text = text.replace(/[\s]+/g, '') // Reemplaza espacios por guiones
      // eslint-disable-next-line no-useless-escape
      text = text.replace(/[^\w\-]+/g, '') // Elimina caracteres no válidos
      text = text.replace(/-+/g, '') // Reemplaza múltiples guiones por uno solo
      text = text.replace(/^-+|-+$/g, '') // Elimina guiones al principio y al final

      this.evento.slug = text
    },
    setImgs() {
      if (this.imgs.length > 0) {
        this.isLoading = true
        const formData = new FormData()
        this.imgs.forEach(element => {
          formData.append('images[]', element)
        })

        this.axios
          .post('events/upload-imgs-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              // this.evento.imgs = res.data.data.dir
              if (res.data.data.imgsNotSize) {
                if (res.data.data.imgsNotSize.length > 0) {
                  res.data.data.imgsNotSize.forEach(element => {
                    this.$toast.error(element)
                  })
                  this.$toast.error(this.$t('msg.msgImgsNotSize', { n: '1080x720' }))
                }
              }
              this.evento.imgs = res.data.data.data
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
        this.evento.dateTo = this.$moment(this.evento.dateFrom)
          .add('days', 0)
          .format('YYYY-MM-DD')
      }, 100)
    },
    setLogo() {
      if (this.logo) {
        this.isLoading = true
        const formData = new FormData()

        formData.append('logo', this.logo)

        this.axios
          .post('events/upload-logo-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.evento.logo = res.data.data.data
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    setCatalog() {
      if (this.pdf) {
        this.isLoading = true
        const formData = new FormData()

        formData.append('pdf', this.pdf)

        this.axios
          .post('events/upload-pdf-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.evento.pdf = res.data.data.data
              this.evento.size_pdf = res.data.data.size
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    save() {
      if (this.evento.title
        && this.evento.slug
        && this.evento.description
        && this.evento.dateFrom
        && this.evento.dateTo) {
        if (localStorage.getItem('event-id')) {
          this.evento.id = localStorage.getItem('event-id')
        }
        this.axios
          .post('events/update-event', this.evento, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.$toast.success(this.$t('msg.infoSuccess'))
              this.$router.push({ name: 'events-list' })
            } else {
              this.$toast.error(res.data.message)
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    updateSocios() {
      let existe = false
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.evento.socios.length; index++) {
        const element = this.evento.socios[index]
        if (element.id === this.socioId) {
          existe = true
          this.evento.socios[index] = this.ssocioId
        }
      }

      if (!existe) {
        this.evento.socios.push(this.socioId)
      }
      this.isDialogSocio = false
      this.socioId = null
    },
    newSocio() {
      this.isDialogSocio = true

      /* this.socio = {
        id: uuidv4(),
        title: null,
        decription: null,
        logo: null,
      }
      this.isSidebarDataSocioActive = true
      */
    },
    editSocio(id) {
      // eslint-disable-next-line prefer-destructuring
      this.socio = this.evento.socios.filter(e => e.id === id)[0]
      this.isSidebarDataSocioActive = true
    },
    deleteSocio(pos) {
      this.evento.socios.splice(pos, 1)
    },
    editImg(imag) {
      this.imgEdit = imag
      this.showImg = true
    },
    confirmDeleteImgEvent() {
      this.isLoading = true
      this.showImg = false
      const json = {
        id: this.evento.id,
        img: this.imgEdit,
      }
      this.axios
        .post('events/delete-imgs-event', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.getEvent()
        })
    },
    addImgs() {
      if (this.imgs.length > 0) {
        this.isLoading = true
        const formData = new FormData()
        this.imgs.forEach(element => {
          formData.append('images[]', element)
        })

        formData.append('data', JSON.stringify({ id: this.evento.id }))

        this.axios
          .post('events/add-imgs-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              // this.evento.imgs = res.data.data.dir
              if (res.data.data.imgsNotSize) {
                if (res.data.data.imgsNotSize.length > 0) {
                  res.data.data.imgsNotSize.forEach(element => {
                    this.$toast.error(element)
                  })
                  this.$toast.error(this.$t('msg.msgImgsNotSize', { n: '1080x720' }))
                }
              }
              if (res.data.data.data.length > 0) {
                this.$toast.success(this.$t('msg.infoSuccess'))
              }

              // this.evento.imgs = res.data.data.data
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.getEvent()
            this.imgs = []
          })
      }
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
